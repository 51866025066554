<template>
	<div class="newestNoticSty">
		<div style="padding: 50px 30px;">
			<div style="font-size: 36px;font-weight: 700;line-height: 50px;">{{noticeDetails.title}}</div>

      <el-divider></el-divider>

			<p style="font-size: 16px;color: #808080;line-height: 30px;">{{noticeDetails.createTime}}</p>
      <br>
			<div v-html="noticeDetails.content" style="text-align: left;margin-left: 80px;margin-right: 80px">

			</div>
		</div>
<!-- 		<div style="width: 100%;height: 20px;">

		</div> -->
	</div>

</template>

<script>
	import {
		noticeDetails
	} from "@/api/index.js"
	export default {
		data() {
			return {
				noticeDetails: {}
			}
		},
		methods: {
			getInfo() {
				let param = {
					id: this.$route.query.id
				}
				noticeDetails(param).then(res => {
					if (res.code == "0000") {
						// console.log(res.data)
						this.noticeDetails = res.data
					}
				})
			}
		},
		mounted() {
			this.getInfo()
		}
	}
</script>

<style lang="scss" scoped>
	.newestNoticSty {
		width: 1280px;
		background-color: #FFFFFF;
		margin: 50px auto;
		box-shadow: 10px 10px 5px #cccccc;
		// position: relative;
		// overflow-y: scroll;

		// .newestNoticContent {
		// 	height: 460px;
		// 	overflow-y: scroll;
		// }
	}
</style>
